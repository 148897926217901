import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import { Observable, finalize, firstValueFrom } from 'rxjs';
import { LoadingService } from './loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  BASE_URL = environment.ADMIN_BASE_URL + '/v1';

  http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  constructor() {}

  getBankList(): Observable<BankListResponse> {
    const uniqueStateUrl = URL_KEYS.COMMON.GET_BANK_LIST;
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .get<BankListResponse>(this.BASE_URL + '/bank/get-bank-list')
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  async getBankName(bankCode: string): Promise<string | undefined> {
    const uniqueStateUrl = URL_KEYS.COMMON.GET_BANK_NAME;
    this.loadingService.setLoading(uniqueStateUrl, true);
    try {
      // Convert the observable to a promise
      const res = await firstValueFrom(this.getBankList());

      const banks = res.data as { bankName: string; bankCode: string }[];

      const bank = banks.find((bank) => bank.bankCode === bankCode);

      return bank ? bank.bankName : undefined;
    } catch (error) {
      console.error('Error fetching bank name:', error);
      return undefined;
    } finally {
      this.loadingService.setLoading(uniqueStateUrl, false);
    }
  }

  // TODO: update method to match the updated API
  // validateAccountNumber(account_number: string, bank_code: string) {
  //   return this.http.post(this.BASE_URL + '/bank/get-bank-list', {
  //     account_number,
  //     bank_code,
  //   });
  // }

  validateBankAccountNo(
    accountNumber: string,
    bankCode: string
  ): Observable<AccountValidationResponse> {
    const uniqueStateUrl = 'validate-bank-details';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post<AccountValidationResponse>(
        this.BASE_URL + '/bank/validate-bank-details',
        {
          account_number: accountNumber,
          bank_code: bankCode,
        }
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  getAllCountriesAndCities(): Observable<unknown> {
    return this.http.get('https://countriesnow.space/api/v0.1/countries');
  }

  getAllNigerianStates(): Observable<unknown> {
    return this.http.get(
      'https://nigeria-states-towns-lga.onrender.com/api/states'
    );
  }
}

interface AccountValidationResponse {
  status: string;
  message: string;
  data: AccountValidationResponseData;
}

interface AccountValidationResponseData {
  bankCode: string;
  accountName: string;
  transactionReference: string;
  bvn: string;
  responseMessage: string;
  accountNumber: string;
  responseCode: string;
}

interface BankListResponse {
  status: string;
  message: string;
  data: BankListDatum[];
}

interface BankListDatum {
  bankCode: string;
  bankName: string;
}
